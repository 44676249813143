export const data = [
    {
        //0
        region: "Eastern",
        sentence: "The lending demand in the Eastern region remained strong with a total of £216,072,443 released in equity in 2021.",
        total_equity_release_lending: "£216,072,443",
        average_housing_wealth: "£310,005",
        num_of_cases: "2,481",
        typical_release: "£87,091",
        ltv: "28%",
        average_interest: "3.57%",
        referral_fee: "£1,537"
    },
    {
        //1
        region: "East Midlands",
        sentence: "The lending demand in the East Midlands region remained strong with a total of £281,845,210 released in equity in 2021. ",
        total_equity_release_lending: "£281,845,210",
        average_housing_wealth: "£272,877",
        num_of_cases: "3,652",
        typical_release: "£77,169",
        ltv: "28%",
        average_interest: "3.49%",
        referral_fee: "£1,741"
    },
    {
        //2
        region: "London",
        sentence: "The lending demand in the London region has remained strong with a total of £942,199,702 released in equity in 2021. ",
        total_equity_release_lending: "£942,199,702",
        average_housing_wealth: "£721,547",
        num_of_cases: "4,243",
        typical_release: "£222,046",
        ltv: "31%",
        average_interest: "3.30%",
        referral_fee: "£3,784"
    },
    {
        //3
        region: "North East",
        sentence: "The lending demand in the North East region has remained strong with a total of £94,949,250 released in equity in 2021. ",
        total_equity_release_lending: "£94,949,250",
        average_housing_wealth: "£222,191",
        num_of_cases: "1,443",
        typical_release: "£65,808",
        ltv: "30%",
        average_interest: "3.67%",
        referral_fee: "£1,527"
    },
    {
        //4
        region: "North West",
        sentence: "The lending demand in the North West region has remained strong with a total of £315,736,632 released in equity in 2021.",
        total_equity_release_lending: "£315,736,632",
        average_housing_wealth: "£245,899",
        num_of_cases: "4,227",
        typical_release: "£74,690",
        ltv: "30%",
        average_interest: "3.62%",
        referral_fee: "£1,482"
    },
    {
        //5
        region: "Scotland",
        sentence: "The lending demand in the Scotland region has increased with a total of £151,979,812 released in equity in 2021.",
        total_equity_release_lending: "£151,979,812",
        average_housing_wealth: "£219,613",
        num_of_cases: "2,305",
        typical_release: "£65,926",
        ltv: "30%",
        average_interest: "3.61%",
        referral_fee: "£1,064"
    },
    {
        //6
        region: "South East",
        sentence: "The lending demand in the South East region has increased with a total of £1,228,678,236 released in equity in 2021.",
        total_equity_release_lending: "£1,228,678,236",
        average_housing_wealth: "£448,089",
        num_of_cases: "9,652",
        typical_release: "£127,291",
        ltv: "28%",
        average_interest: "3.41%",
        referral_fee: "£2,581"
    },
    {
        //7
        region: "South West",
        sentence: "The lending demand in the South West region has remained strong with a total of £516,298,758 released in equity in 2021.",
        total_equity_release_lending: "£516,298,758",
        average_housing_wealth: "£365,397",
        num_of_cases: "4,919",
        typical_release: "£104,951",
        ltv: "29%",
        average_interest: "3.44%",
        referral_fee: "£2,193"
    },
    {
        //8
        region: "Wales",
        sentence: "The lending demand in the Wales region has remained strong with a total of £161,733,606 released in equity in 2021.",
        total_equity_release_lending: "£161,733,606",
        average_housing_wealth: "£242,924",
        num_of_cases: "2,231",
        typical_release: "£72,501",
        ltv: "30%",
        average_interest: "3.58%",
        referral_fee: "£1,935"
    },
    {
        //9
        region: "West Midlands",
        sentence: "The lending demand in the West Midlands region has remained strong with a total of £277,468,832 released in equity in 2021.",
        total_equity_release_lending: "£277,468,832",
        average_housing_wealth: "£270,839",
        num_of_cases: "3,493",
        typical_release: "£79,445",
        ltv: "29%",
        average_interest: "3.48%",
        referral_fee: "£1,464"
    },
    {
        //10
        region: "Yorkshire & Humberside",
        sentence: "The lending demand in the Yorkshire and Humberside region has remained strong with a total of £193,325,491 released in equity in 2021.",
        total_equity_release_lending: "£193,325,491",
        average_housing_wealth: "£222,067",
        num_of_cases: "3,003",
        typical_release: "£64,383",
        ltv: "29%",
        average_interest: "3.59%",
        referral_fee: "£1,477"
    },
    {
        //11
        region: "Northern Ireland",
        sentence: "The lending demand in the Northern Ireland region has remained strong with a total of £19,993,172 released in equity in 2021.",
        total_equity_release_lending: "£19,993,172",
        average_housing_wealth: "£186,804",
        num_of_cases: "341",
        typical_release: "£58,676",
        ltv: "31%",
        average_interest: "3.69%",
        referral_fee: "£1,339"
    },
]
