import React from 'react';
import { configureStore } from '@reduxjs/toolkit'
import regionReducer from './region';

export default configureStore({
  reducer: {
      region: regionReducer
  }
})

