import React from 'react'
import { createSlice } from '@reduxjs/toolkit'


const regionSlice = createSlice({
    name: "region",
    initialState: {
        loading: false,
        hasErrors: false,
        region: {
            region: "",
            sentence: "",
            total_equity_release_lending: "",
            average_housing_wealth: "",
            num_of_cases: "",
            typical_release: ""
        } 
    },
    reducers: {
        getRegion: (state) => {
            state.loading= true
        },
        getRegionSuccess: (state, {payload}) => {
            state.region = payload
            state.loading= false
            state.hasErrors = false
        },
        getRegionFailure: (state) => {
            state.loading = false
            state.hasErrors = true
        }
    }
})

export const {getRegion, getRegionSuccess, getRegionFailure} =regionSlice.actions
export default regionSlice.reducer
export const regionSelector = (state: any) => state.region

export function showRegion(region :any) {
    return async (dispatch: any) => {
        dispatch(getRegion())
        try { 
            dispatch(getRegionSuccess(region))
        }catch(err){
            console.log(err)
            dispatch(getRegionFailure())
        }
    }
}