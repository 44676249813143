import React from "react";
import Information from "../components/Information";
import Map from "../components/Map";

const MainPage = () => {
  return (
    <div>
      <section className="lit-local-intelligence">
        <div className="container">
          <div className="lit-local-head-top">
            <h1>Equity Release 
Market Tool</h1>
            <p>
              We've gathered countrywide data on equity release activity.<br />
              Click your region on the map below for insight on your area:
            </p>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="lit-map-box">
                <Map />
              </div>
            </div>

            <div className="col-md-6">
              <div className="lit-right-figures">
                  <Information />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MainPage;
