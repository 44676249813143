import React from 'react'
import {useSelector} from 'react-redux'
import {regionSelector} from '../slices/region'
import support from "../assets/images/support.svg"
import download from "../assets/images/download.svg"
import telephone from "../assets/images/telephone.svg"
const Information = () => {
    const { region,loading, hasErrors } = useSelector(regionSelector);
    console.log(region.region == "")
    return (
        <>
        {region.region == "" ? " " : <>
        <h2>FIGURES*</h2>
                        <div className="lit-table-outer-box">
                            <table className="lit-table">
                                <tbody>
                                  <tr>
                                    <td>Region</td>
                                    <td><span>{region.region}</span></td>
                                  </tr>
                                  <tr>
                                    <td>Total equity release lending</td>
                                    <td><span>{region.total_equity_release_lending}</span></td>
                                  </tr>
                                  <tr>
                                    <td>Average housing wealth</td>
                                    <td><span>{region.average_housing_wealth}</span></td>
                                  </tr>
                                  <tr>
                                    <td>Number of cases</td>
                                    <td><span>{region.num_of_cases}</span></td>
                                  </tr>
                                  <tr>
                                    <td>Typical release / LTV</td>
                                    <td><span>{region.typical_release} / {region.ltv}</span></td>
                                  </tr>
                                  <tr>
                                      <td>Average interest rate</td>
                                      <td><span>{region.average_interest}</span></td>
                                  </tr>
                                  <tr>
                                      <td>Average referral fee</td>
                                      <td><span>{region.referral_fee}</span></td>
                                  </tr>
                                </tbody>
                              </table>
                        </div>

                          <div className="lit-table-bottom-text">
                              <h3>Marketing support we offer</h3>
                              <p>Our comprehensive range of marketing assets and tools will support you in generating quality leads and enable cost effective growth of your business.</p>
                          </div>
                          <div className="lit-download-marketing-box">
                              {/*<a target="_blank" href={process.env.PUBLIC_URL + "/MABLL015 Equity Release Guide_no contact details.pdf"}><img src={download} alt="" /> Download Equity Release Guide</a>*/}
                              {/*<a target="_blank" href="https://adviser.mablaterlife.com/working-together/marketing-support"> <img src={support} alt="" /> Marketing support and collateral</a>*/}
                              <a target="_blank" className="hidden-arrow"> <img src={telephone} alt="" /> Call us on 0800 464 3677</a>
                          </div>
                          <p className="lit-copyright-text-bottom">*2021 UK Equity Release Market Monitor, Key Group</p></> }
        </>
    )
}

export default Information
